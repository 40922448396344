/* Navbar styles */
.navbar {
  background-color: #001E30 !important;
  padding: 1rem 0;
}

img.logo {
  width: 5vh;
  height: 5vh;
  margin-right: 1rem;
}

.navbar-light .navbar-brand {
  color: white;
  font-size: 4vh;
  font-weight: 700;
  line-height: 54px;
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:visited,
.navbar-light .navbar-brand:active {
  color: white !important;
}

.navbar-light .navbar-nav li .nav-link {
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 160%;
  /* padding-right: 2vw; */
}

.navbar-light .navbar-nav li:nth-child(4) .nav-link {
  border-radius: 6px;
  background: var(--Schemes-On-Primary, transparent);
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.8px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #FFF;
}

.navbar-light .navbar-nav li:nth-child(5) .nav-link {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  background: var(--Schemes-On-Primary, transparent) !important;
  border: 1px solid var(--Schemes-On-Primary, #FFF);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Schemes-On-Secondary-Container, #AED18D);
  margin: 0 1rem 0 2rem;
}

#navbarNav > ul > li:nth-child(6) > a{
  padding: 0.5rem 2rem ;
}

#navbarNav > ul > li:nth-child(5) > a{
  padding: 0.5rem 2rem ;
}



.navbar-brand .logo img {
  width: 50px;
  height: 50px;
}

.brand-name {
  color: var(--Schemes-On-Primary, #FFF);
  font-size: 42px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: -0.84px;
  margin-left: 10px;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 83px;
}

.nav-item .nav-link {
  color: #FFF;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.nav-item .btn-primary {
  background-color: #FFF;
  color: #3a6ea5;
  border: none;
}

.nav-item .btn-success {
  background-color: #AED18D;
  color: black !important;
  border: none;
}

/* Estilos para el botón de hamburguesa */
.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler-icon {
  width: 30px;
  height: 3px;
  background-color: #FFF; /* Mantener el color blanco para el icono */
  display: block;
  position: relative;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: #FFF; /* Mantener el color blanco para las líneas */
  display: block;
  position: absolute;
  left: 0;
}

.navbar-toggler-icon::before {
  top: -10px;
}

.navbar-toggler-icon::after {
  bottom: -10px;
}

/* Estilos para el menú desplegable en vista móvil */
.mobile-menu {
  display: none;
  position: fixed;
  top: 0; /* Ensure the menu starts from the top */
  right: 0;
  width: 45%; /* Full width for better mobile view */
  height: 100%;
  background-color: #001E30; /* Mantener el color del fondo del menú */
  padding: 20px;
  z-index: 9999; /* Máximo z-index posible */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.mobile-menu.show {
  transform: translateX(0);
  display: block; /* Mostrar el menú cuando tenga la clase 'show' */
}

.close-button {
  background: none;
  border: none;
  color: #FFF;
  font-size: 2rem;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.mobile-nav {
  list-style: none;
  padding: 0;
  margin-top: 60px; /* Ajuste para evitar solapamiento con el botón de cierre */
}

.mobile-nav .nav-item {
  margin-bottom: 15px;
}

.mobile-nav .nav-link {
  color: #FFF; /* Mantener el color blanco para los enlaces */
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.mobile-nav .nav-link .nav-icon {
  margin-right: 10px;
}

/* Mostrar el botón de hamburguesa y ocultar el menú de escritorio en vista móvil */
@media (max-width: 750px) {
  .navbar-toggler {
    display: block;
  }

  .navbar-collapse {
    display: none !important;
  }

  .mobile-menu {
    display: block;
  }

  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Ensure it stays on top of other elements */
  }

  .navbar .logo {
    margin: 1vh; 
    height: 5vh; /* Ajustar tamaño del logo */
  }

  .navbar-light .navbar-brand {
    font-size: 3vh; /* Ajustar tamaño del título */
  }

  /* Ocultar enlace "Home" en versiones de escritorio */
  .navbar-nav .nav-item.d-lg-none {
    display: none;
  }

  /* Mostrar enlace "Home" solo en versiones móviles */
  @media (max-width: 750px) {
    .navbar-nav .nav-item.d-lg-none {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .navbar-brand .brand-name {
    font-size: 24px;
    line-height: 32px;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .nav-item .nav-link,
  .nav-item .btn-primary,
  .nav-item .btn-success {
    font-size: 14px;
  }
}

.budgetbuddy {
  position: relative;
  font-size: 22.8px;
  font-weight: 600;
  font-family: Urbanist;
  color: #fff;
  text-align: left;
}
