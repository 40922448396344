.form-container-unique {
  width: 25%;
  /* background-color: #444; */
  /* padding: 20px; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.form-group-unique {
  margin-bottom: 1rem;
  width: 20rem;
}

.label-unique {
  color: white;
  margin-bottom: 5px;
  display: block;
  font-size: 0.8vw;
}

.input-unique {
  height: 2.5rem;
}

.input-unique,
.textarea-unique {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-unique::placeholder {
  color: #aaa;
}

.banner-unique {
  /* font-weight: bold; */
  font-size: 1.2em;
}

.textarea-unique {
  height: 100px;
}

.submit-btn-unique {
  background-color: #aed18d;
  color: black;
  padding: 3% 25%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn-unique:hover {
  background-color: #98c577;
}
