.features-section {
  padding: 50px 0;
}

.features-section h2 {
  color: #001e30;
  font-size: 4vw;
  margin-bottom: 2%;
  font-weight: 600;
  position: relative;
  letter-spacing: -0.02em;
  font-family: Geologica;
}

.feature-row {
  margin-bottom: 3%;
}

.feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature-item.reverse {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.feature-content {
  flex: 1;
  text-align: left;
}

.feature-content h3 {
  margin-bottom: 15px;
  font-size: 38px;
  letter-spacing: -0.02em;
  line-height: 57px;
}

.feature-content p {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.feature-image {
  flex: 1;
  text-align: right;
}

.feature-image-2 {
  flex: 1;
  text-align: left;
}

.feature-image img {
  border-radius: 8px;
  width: 80%;
  margin: 5% 0 0 0;
}

.reverse .feature-image img {
  width: 90% !important;
}

div.feature-content-1 {
  background-color: #001e30;
  color: white;
  padding: 9% 5%;
  border-radius: 10px;
}

div.feature-content-2 {
  background-color: #0d2000;
  color: white;
  padding: 9% 5%;
  border-radius: 10px;
}

div.feature-content-3 {
  background-color: #3a608f;
  color: white;
  padding: 9% 5%;
  border-radius: 10px;
}

.feature-image-3 img {
  width: 100% !important;
}

.feature-image-3 {
  text-align: right;
  margin-left: 5%;
}

@media (max-width: 768px) {
  .features-section h2 {
    font-size: 8vw;
    margin-bottom: 10%;
    font-weight: 600;
    position: relative;
    letter-spacing: -0.02em;
    font-family: Geologica;
  }

  .features-section {
    padding: 0;
  }

  .feature-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .feature-item.reverse {
    flex-direction: column;
  }

  .feature-content {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    padding: 0 10%; /* Optional: Adjust padding for better spacing */
  }

  .feature-image {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .feature-image img {
    width: 80%;
    margin: 0 auto;
  }

  /* Orden personalizado para la versión móvil */
  .feature-content-1,
  .feature-content-2,
  .feature-content-3 {
    order: 2;
  }

  .feature-item:nth-child(1) .feature-image {
    order: 1;
  }

  .feature-item:nth-child(2) .feature-image {
    order: 3;
  }

  .feature-item:nth-child(3) .feature-image {
    order: 5;
  }

  .feature-item:nth-child(1) .feature-content h3,
  .feature-item:nth-child(2) .feature-content h3,
  .feature-item:nth-child(3) .feature-content h3 {
    order: 4;
  }

  .feature-item:nth-child(1) .feature-content p,
  .feature-item:nth-child(2) .feature-content p,
  .feature-item:nth-child(3) .feature-content p {
    order: 6;
  }

  .feature-item:nth-child(1) .feature-content-1 {
    order: 2;
  }

  .feature-item:nth-child(2) .feature-content-2 {
    order: 4;
  }

  .feature-item:nth-child(3) .feature-content-3 {
    order: 6;
  }

  .features-section .container {
    padding: 0;
  }

  div.feature-content-1,
  div.feature-content-2,
  div.feature-content-3 {
    border-radius: 0;
  }

  .feature-row {
    margin-bottom: 0;
  }
}
