
.image-options{
  display: flex !important;
  flex-flow: row wrap !important;
  justify-content: center;
  align-items: center;
  gap:20px;
  button.internal-btn{
    cursor: pointer;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    gap: 10px;
    justify-content: center;
    background-color: rgb(0, 30, 48);
          color: white;
          display: block;
          padding: 1.5rem !important;
          border-radius: 15px;
          border: none;
          svg{
            font-size: 2rem;
          }
  }

}
.custom-file, .webcam-container{
  margin-top:50px;
  margin-bottom:30px;

}
.addTransactions{
  width: 100%;
  padding: 0;
  margin: 0 auto;

  @media only screen and (min-width: 600px) {
   
    
  }

 
  .transaction_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    gap:20px;
    margin-bottom: 20px;
    h1{
      justify-self: flex-start;
      color: #001E30;
      text-align: left;
      font-size: clamp(20px, 3vw, 38px);
      font-weight: 700;
    }
    h2{
      justify-self: flex-end;
      color: #001E30;
      text-align: right;
      font-size: clamp(18px, 3vw, 30px);;
      font-weight: 500;
    
    }
    label{
      font-size: clamp(16px, 3vw, 20px);
      
    }
  }
 
}

.transaction-options {
  display: flex;
  flex-flow: row wrap;
  gap:10px;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;

  .transaction-option {
    cursor: pointer;
    text-align: center;
    font-size: 1.5em;
    &:hover{
      background-color: rgba(0, 30, 48, 0.1);
    }
    &.form-check {
      position: relative;
      display: block;
      padding: 1.5rem !important;
      border-radius:15px;
      color: #001E30;
  }
    &:has(.form-check-input:checked){
      background-color: rgb(0, 30, 48);
      color: white;
    }
    
    .form-check-input{
     display: none;
    }
    .form-check-label{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      justify-content: center;
      svg {
        font-size: 2.5em;
        display: block;
        margin-bottom: 10px;
      }
    }
    }
   
  
}
.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  .form-control {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #CBD5E1;
    font-size: 1.2em;
    min-height: 50px;
  }
  .upload-btn{
    border: 1px solid #CBD5E1;
    background: #ffffff;
    color:#181C20;
  }
}

.buttons_footer{
  display: flex;
  flex-flow: row nowrap;
  gap:10px;
  align-items: center;
  justify-content: flex-end;
  .btn-primary{
    background-color: #49672E;
  }
  .btn-secondary{
    background-color: #CBE6FF;
    border: 1px solid #1F4876;
    color: #0F172A;
  }
}



.amount-field {
  display: flex;
  align-items: center;
}

.amount-field .icon-dollar {
  margin-left: -40px;
  font-size: 1.5em;
  color: #666;
}

.date-field {
  display: flex;
  align-items: center;
}

.date-field .icon-calendar {
  margin-left: -50px;
  font-size: 1.5em;
  color: #666;
  display: none;
}

.webcam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.webcam {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.upload-btn {
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  font-size: 1.2em;
}
