.scrollable-table {
  max-height: 63.2vh;
  overflow-y: auto;
  border: 1px solid #3a608f;
 &::-webkit-scrollbar {
  width: 12px;
}
&::-webkit-scrollbar-track {
  background: #f1f1f1;
}
&::-webkit-scrollbar-thumb {
  background: #3a608f;
}
&::-webkit-scrollbar-thumb:hover {
  background: #3A608F;
}
scrollbar-width: thin;
      scrollbar-color: #3a608f #f1f1f1;
}

.scrollable-table-dashboard {
  overflow-y: none;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  border: 0px;
  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.responsive-table-latest {
  box-shadow: none;
}

thead {
  position: sticky;
  top: 0;
  background-color: white;
  border: none;
  z-index: 1;
}

/* Apply background and text color to table header */
.responsive-table th {
  background-color: #3a608f;
  color: white;
  padding: 0.5rem;
  border: 1px solid white;
  border-top: 0;
display:table-cell;
div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
}
}
.responsive-table tbody {
  tr.negative {
 
  td{
    background-color: #faf2f2 !important;
  }
}
tr.positive {
 
  td{
    background-color: #f6f9ff !important;
  }
}
}

.responsive-table th {
  border-top: 0;
}

.plus {
  color: blue;
}

.minus {
  color: red;
}

.icons a {
  color: #3a608f;
}

/* Styles for screens smaller than 900px */
@media (max-width: 900px) {
  .apply-button {
    width: 100%;
  }
}

/* Styles for screens smaller than 820px */
@media (max-width: 820px) {
  .responsive-table thead {
    display: none;
  }

  .responsive-table tr {
    display: block;
    margin-bottom: 1rem;
  }

  .responsive-table td {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0;
    border-top: 1px solid #ddd;
  }

  .responsive-table td:first-child {
    border-top: none;
  }

  .responsive-table td::before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 1rem;
    padding: 0rem;
    display: block;
    max-width: auto;
    color: black;
  }

  .responsive-table tbody tr {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  .icons a {
    padding: 0 0.5rem 0 1rem;
  }

  /* Ensure the fields are stacked vertically */
  .filter-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-bottom: 2rem;
  }

  .filter-container > div > div {
    margin-bottom: 0 !important;
  }

  .type-wapper {
    width: 157px;
  }
}

/* Styles for screens larger than 820px */
@media (min-width: 821px) {
  .responsive-table {
    width: 100%;
  }

  .responsive-table tr {
    display: table-row;
    border-bottom: 1px solid #ddd;
  }

  .responsive-table tr:last-child {
    border-bottom: none;
  }

  .responsive-table tbody {
    
  }

  .responsive-table td {
    display: table-cell;
    padding: 0.5rem;
    font-size: 0.95rem;
  }

  .responsive-table td::before {
    content: none;
  }

  .responsive-table th,
  .responsive-table td {
    width: auto;
  }

  .responsive-table td:nth-child(5) {
    text-align: right;
    padding-right: 3%;
  }

  .type-wapper {
    width: 140px;
  }

  .icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding-right: 0.5rem;
  }

  /* Ensure the fields are in a row */
  .filter-container {
    display: flex;
    flex-direction: row;
  }

  .filter-container .field {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
