/* BudgetPage.css */

/* Initial styles for large screens */
/* .shrinked-nav {
  display: none;
} */

.sc-hzhKNl.etnNfb {
  display: none;
}

.container1 {
  display: flex;
  flex-direction: row;
}

/* Styles for smaller screens */
@media (max-width: 1024px) {
  .container1 {
    flex-direction: column;
  }

  aside {
    display: none;
  }

  /* .shrinked-nav {
  display: none;
} */

  .sc-hzhKNl.etnNfb {
    display: block;
  }

  .shrinked-nav ul {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }
}
