@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@700&display=swap");

.team-section {
  padding: 50px 0;
}

.team-section h1 {
  position: relative;
  font-size: 4vw;
  font-weight: bold;
  letter-spacing: -0.02em;
  line-height: 108px;
  font-family: Geologica;
  color: #001e30;
  margin-bottom: 2rem;
}

.team-section h2 {
  font-size: 2.3vw;
  line-height: 70px;
  font-weight: 500;
  color: #001e30;
  text-align: center;
  padding: 0 0 1rem 0;
}

.team-section h3 {
  width: 100%;
  font-size: 20px;
  color: #000;
  text-align: left;
  overflow-wrap: break-word; /* Allow long words to break and wrap to the next line */
}

.team-section p {
  font-size: 14px;
  color: #000;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}

.team-title {
  display: flex;
  justify-content: center; /* Center the content horizontally */
}

.team-title img {
  width: auto;
  height: 70px; /* Make the image size closer to the text */
  padding-right: 1%;
}

.team-title h2 {
  font-weight: bold; /* Make the title bold */
}

.team-member {
  margin-bottom: 2rem;
  padding: 1rem; /* Add padding to ensure there's space around the content */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.team-member img {
  margin-bottom: 1rem; /* Add space below the image */
}

@media (max-width: 768px) {
  .team-section {
    padding: 20px 0;
  }

  .team-section h1 {
    font-size: 10vw;
    line-height: 12vw;
  }

  .team-section h2 {
    font-size: 8vw;
    line-height: 9vw;
    padding: 1rem 0;
  }

  .team-title {
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .team-title img {
    height: 50px;
    width: auto;
    margin: 0 10px 0 0;
  }

  .team-title h2 {
    font-size: 5vw;
    font-weight: bold; /* Ensure boldness on mobile */
  }

  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem; /* Add padding to ensure there's space around the content */
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  }

  

 

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }
}
