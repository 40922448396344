.products {
    width: 100%;
    padding: 0;
    margin: 0 auto;

  


    .products_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: 20px;
      

        h1 {
            justify-self: flex-start;
            color: #001E30;
            text-align: left;
            font-size: clamp(20px, 3vw, 38px);
            font-weight: 700;
        }

        h2 {
            justify-self: flex-end;
            color: #001E30;
            text-align: right;
            font-size: clamp(18px, 3vw, 30px);
            ;
            font-weight: 500;

        }

        label {
            font-size: clamp(16px, 3vw, 20px);

        }
    }

    .product_layout {
        display: flex;
        flex-flow: row nowrap;
        gap: 1.5rem;
        aside {
            width: 250px;
            padding: 0 0 0 0;
            margin-bottom: 0;
            display: flex;
            flex-flow: row wrap;
         
            .products_sidebar {
                width: 100%;
                ul {
                    
                    display: flex;
                    flex-flow: column nowrap;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    text-decoration: none;
                    padding: 5px;
                    border-radius: 6px;
border: 1px solid #FFF;
box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
                    li {
                        position: relative;
                        display: block;
                   
                        background-color: #fff;
                      
                        list-style: none !important;

                        a {
                            display: flex;
                            min-width: 200px;
                            padding: 6px 8px 6px 18px;
                            align-items: center;
                            gap: 12px;
                            align-self: stretch;
                            align-items: center;
                            text-decoration: none;
                            font-size: 14px;
                            letter-spacing: 0.02em;
                            line-height: 28px;
                            color: #334155;
                            text-align: left;
                            font-weight: 600;
                            border-radius: 6px;
                            
&.active{
    background:  #F1F5F9;
}
                            span {
                                flex-grow: 1;
                            }

                            &:hover {
                                background:  #F1F5F9;
                            }
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 968px) {

                flex-flow: column nowrap;
                aside {
                    width: 100%!important;
                    flex-grow: 1;
                }

        }        .products_main {
            flex-grow: 1;
            flex-shrink: 1;
            display: grid;
            grid-template-columns: repeat(1, 1fr);

            grid-auto-rows: auto auto auto auto auto auto;
            
            @media only screen and (min-width: 740px) {

                grid-template-columns: repeat(2, 1fr);
            }
            @media only screen and (min-width: 1300px) {

                grid-template-columns: repeat(3, 1fr);
            }
            gap: 20px;

            .product_container {
                border-radius: 20px;
                border: 1px solid #D3E3FF;
                background: #FFF;
                padding: 20px;
                display: grid;
                grid-row: span 5;
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: subgrid;
                gap: 10px;

                h2 {

                    margin-bottom: 0;
                    color: #000;

                    /* Text Bold/Text LG Bold */
                    font-family: "DM Sans";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    /* 160% */
                }

                p,
                li {
                    overflow: hidden;
                    color: #000;

                    text-align: right;
                    text-overflow: ellipsis;
                    /* Text/Text SM (Default) */
                    font-family: "DM Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    /* 150% */
                }

                p {
                    text-align: left;
                }

                img {
                    width: 100% !important;
                    height: 100%;
                    object-fit: contain;
                }

                .product_footer {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    .btn-black {
text-decoration: none;
                        border-radius: 6px;
                        background: #001E30;
                        color: white;
                        display: flex;
                        width: 124px;
                        padding: 8px 16px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                    }
                    .btn-black:hover {
                        background: #334155;
                        color:white;
                    }
                    .btn-white{
                        text-decoration: none;
                        border-radius: 6px;
                        background: #f0f0f0;
                        color:#001E30;
                        display: flex;
                        width: 124px;
                        padding: 8px 16px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                    }
                    .btn-white:hover {
                        background: #ebebeb;
                        color:#334155;
                    }

                }
            }
        }
    }
}