.forgot-password-background {
    opacity: 0.85;
    background: var(--Schemes-On-Primary-Container, #29638a);
    background-image: url(../Assets/background-signup.png);
    mix-blend-mode: multiply;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .card {
    background: white;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .btn-primary {
    background-color: #001F3F;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #001F3F;
  }
  
  .form-control {
    border-radius: 5px;
    padding: 10px;
  }
  
  .forgot-password-background-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;
  }
  
  .Card-cont {
    width: 100%;
    max-width: 400px; /* Adjust this value as needed */
    margin-right: 5%; /* Adjust this value for responsive space */
  }
  
  .submit-btn-forgot {
    background-color: #0f172a !important;
  }
/*   
  @media (min-width: 1920px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 65%;
    }
  } */
 
  @media only screen and (max-width: 768px) {
    .forgot-password-background-container {
      flex-direction: column;
      justify-content: flex-end !; /* Alinear elementos en la parte inferior */
      align-items: center;
      padding: 0;
    }
  }
  